import React from 'react';
import Layout from '../../components/common/Layout';
import Support from '../../components/support/Support';
import SEO from '../../components/SEO';

const SupportPage = () => (
  <Layout lang="en">
    <SEO
      title="Support - Nextcloud Hosting"
      description="Need help? Contact us."
    />
    <Support />
  </Layout>
);

export default SupportPage;
